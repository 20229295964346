import 'reflect-metadata'
import { createApp, defineComponent } from "vue";
import BaseComponent from '@base/component'
import LoginModel from '@models/login'
import { getUrlVars } from '@utils/query'
import ErrorsComponentVue from '@components/errors/errors.vue'
import MsgsComponentVue from '@components/msgs/msgs.vue'

var model = new LoginModel()

const LoginComponent = defineComponent({
    mixins: [BaseComponent],
    components: {
        errors: ErrorsComponentVue,
        msgs: MsgsComponentVue
    },    
    data() {
        return {
            model
        }
    },
    methods: {
        submit: function (evt) {
            evt.preventDefault();
            var vars = getUrlVars();
            if (vars['confirmation_code'])
                this.model.confirmation_code = vars['confirmation_code'];
            this.model.sync("/login/auth");
            return false;
        }
    }
});

model.fetchData().then(data => {
    const loginApp = createApp(LoginComponent)
    loginApp.mount("section#login")
});
